//import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp, router} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy-js';
import * as Sentry from "@sentry/vue";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({el, App, props, plugin}) {

		const app = createApp({render: () => h(App, props)});

		if (import.meta.env.MODE === 'production') {
			Sentry.init({
				app,
				dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
				environment: import.meta.env.MODE,
				integrations: [
					new Sentry.BrowserTracing({
						tracePropagationTargets: ['https://demo.channelmotive.nl', 'https://app.channelmotive.nl'],
						routingInstrumentation: inertiaRoutingInstrumentation,
					}),
				],
				// Performance Monitoring
				tracesSampleRate: 1.0, // Capture 100% of the transactions
				// Session Replay
				replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
				replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			});
		}

		app
			.use(plugin)
			.use(ZiggyVue, Ziggy)
			.mount(el);

		return app;

		// return createApp({render: () => h(App, props)})
		//     .use(plugin)
		//     .use(ZiggyVue, Ziggy)
		//     .mount(el);
	},
	progress: {
		color: '#289AE0',
	},
});

function inertiaRoutingInstrumentation(
	customStartTransaction,
	startTransactionOnPageLoad = true,
	startTransactionOnLocationChange = true,
) {
	let activeTransaction;
	let name;
	if (startTransactionOnPageLoad) {
		name = route().current();
		activeTransaction = customStartTransaction({
			name,
			op: 'pageload',
			metadata: {
				source: 'route',
			},
		});
	}

	if (startTransactionOnLocationChange) {
		router.on('before', (_to, _from) => {
			if (activeTransaction) {
				activeTransaction.finish();
			}

			const newName = route().current();

			if (newName !== name) {
				activeTransaction = customStartTransaction({
					name: newName,
					op: 'navigation',
					metadata: {
						source: 'route',
					},
				});
			}
		});

		router.on('finish', () => {
			activeTransaction.setName(route().current(), 'route');
		});
	}
}
